
elId=""


initialmessage=false

function showInstructions()
{
apprise($("#ibox").val().split("\n").join("<br>"))	
}

function displayHexagons(loadedData,hex_id,hex_x,hex_y,cols,hex_type,hex_fontcolor)
{	
	debugger;
		$("#cancelBut").show()
		currentlydragging=0
		h=loadedData.split("\n");
		str=""
		totalhexagons=h.length;
		for(n=0;n<h.length;n++)
			{
			str+='<div id="h'+ hex_id[n] + '_' + hex_type[n]+'" class="hexagon"><div class="hexagontent">'+h[n]+'</div></div>'
			}
		
		$(".honeycomb").html(str)
		
		for(n=0;n<h.length;n++)
			{
				if(!$("#h"+ hex_id[n] + '_' + hex_type[n]).is(":ui-draggable"))//Adds draggable immediately to existing hexagons.
				{
					$("#h"+ hex_id[n] + '_' + hex_type[n]).draggable({
							stack: ".hexagon",
							scroll: false,
							start: function(event, ui) //draggable:
							{
							$("#iconsWrapper").hide()
							currentlydragging=1; playSound("upAudio") ;beingdragged = event.target.id;//console.log(beingdragged);
							},
							stop: function( event, ui ) //snappable:
							{
							currentlydragging=0;playSound("downAudio");checkforhit(beingdragged);$( this ).removeClass("zoomIn").addClass("zoomOut");
							$('.hexagon').css('cursor', 'move');
							}
					});
				 }   
				   
			}
		
		  
		//Zoom in when MouseDown
		$('.honeycomb').on('touchstart mousedown', '.hexagon', function () {
			if(currentlydragging==0)
				{
				$("#iconsWrapper").hide()
				$('.hexagon').css('z-index','1');
				$( this ).css('z-index','1000');//bring to front.
				$(this).addClass("zoomIn");
				}
		})
		  
		//Zoom back out when MouseUp
		$('.honeycomb').on('touchend mouseup', '.hexagon', function () {
			//console.log("editingText = "+editingText)
			if(editingText!=true)
				{
				$( this ).removeClass("zoomIn").addClass("zoomOut");
				}
		})
		  
		//Show icons on MouseEnter
	$('.honeycomb').on('mouseenter', '.hexagon, .hexagontent', function (e) {
		debugger;
			var target = $(e.target);
			newelId = target.attr('id');
			//console.log("ElID="+elId)
			if(newelId==undefined)
				{
				//console.log("text hover");
				newelId=$(this).parent().attr("id");
				}
			if(newelId!=undefined)
				{
				elId=newelId
				c=$("#"+elId).offset()
				t=c.top;l=c.left;
                cT=  parseInt($("#"+elId).css('top').replace("px"));
				cL = parseInt($("#"+elId).css('left').replace("px"));
				//var menu = parseInt($("#nav-container").css("width").replace("px")) + parseInt($("#nav-container").css("margin-left").replace("px"));
				var headerT = $("#appHeader");
				var headerCSS= $("#appHeader").css("height");
				var headerR=  parseInt($("#appHeader").css("height").replace("px")) ;
				var header = parseInt($("#appHeader").css("height").replace("px")) ;
				
				$("#iconsWrapper").css({ "top": cT-85, "left": cL - 15 })
				if(currentlydragging==0){$("#iconsWrapper").show()}
				}
		})
		
		//Remove icons on MouseOut
		$('.honeycomb').on('mouseout', '.hexagon', function (e) {
			
			var target = $(e.target);
			newelId = target.attr('id');
			if(newelId!=undefined){elId=newelId;$("#iconsWrapper").hide()}
		})
		
		
		
		//Make hexagons draggable / snappable.	
		$(".honeycomb").draggableChildren(".hexagon", {
			stack: ".hexagon",
			scroll: false,
			start: function(event, ui) //draggable:
					{
					$("#iconsWrapper").hide()
					currentlydragging=1; playSound("upAudio") ;beingdragged = event.target.id;//console.log(beingdragged);
					},
				stop: function( event, ui ) //snappable:
					{
					currentlydragging=0;playSound("downAudio");checkforhit(beingdragged);$( this ).removeClass("zoomIn").addClass("zoomOut");
					$('.hexagon').css('cursor', 'move');
					}
		});
		
		//bug fix 
		$('#iconsWrapper').on("mouseenter", function (e) {
			$("#iconsWrapper").show()
		});
		
		
		if(loadingFromServer==0) //Display the initial hexagons in a honeycomb
		{ 
		t=150;l=53;dir=1
		for(n=1;n<=h.length;n++)
			{
			l+=102
			if((l+150)>$(window).width())
				{
				l=155;t+=58;if(dir==1){t+=60}
				dir=1;	
				}
			$("#h"+n).css({"top":t,"left":l,"background-color":"#6699FF"})
			if(dir==1)
				{t+=60}
			else{t-=60}
			if(dir==1){dirnew=-1}
			if(dir==-1){dirnew=1}
			dir=dirnew
			}
		}
		else //Display pre-saved hexagons in their proper layout / colour
		{
			var navcon= $("#snav");
			var menu = parseInt($("#snav").css("width").replace("px")) + parseInt($("#snav").css("margin-left").replace("px"));
			menu = 20;
			for(n=0;n<h.length;n++)
			{
				$("#h" + hex_id[n] + '_' + hex_type[n]).css({ "top": hex_y[n], "left":menu -50 + hex_x[n]})
			$("#h"+ hex_id[n] + '_' + hex_type[n]).css({ 'background-color' : ''})
			$("#h"+ hex_id[n] + '_' + hex_type[n]).css({"background-color":"#"+cols[n]})
			$("#h"+ hex_id[n] + '_' + hex_type[n]).css({"color":"#"+hex_fontcolor[n]})
			//console.log(hex_x[n])
			}
			loadingFromServer=0 //so that if edit, then create, we get the honeycomb rather than the old layout.
		
		}
		
		$(".hexagon, #options, #activityTitle").show()
		
		if(initialmessage==false)
		{initialmessage=true;//apprise("To create a new hexagon, double-click anywhere in the canvas.");
		}
		
			
					
}

$.fn.extend({//Custom function to propagate behaviour to newly created hexagons.
		draggableChildren: function(selector, opts) {
			$(this).on("mouseover touchstart click", selector, function() {
			   if(!$(this).is(":ui-draggable"))
				   $(this).draggable(opts);
			});
		}
	});


function checkforhit(beingdragged)
{
	//console.log(totalhexagons)
	for(n=1;n<=totalhexagons;n++)
		{
		if("h"+ hex_id[n] + '_' + hex_type[n]==beingdragged){continue;}//don't check if it overlaps itself!
		result=collision($('#'+beingdragged), $('#h' +hex_id[n] + '_' + hex_type[n]))
		if(result!=false){break}
		}
    if(result!=false)
		{
	 	$('#result').text=result
	 	dropx=$('#h'+ hex_id[n] + '_' + hex_type[n]).position().left
	 	dropy=$('#h'+ hex_id[n] + '_' + hex_type[n]).position().top
	 	dragx=$('#'+beingdragged).position().left
	 	dragy=$('#'+beingdragged).position().top
	 	if(result=="top center"){$('#'+beingdragged).animate({top: dropy-h2, left: dropx}, 'fast')}
	 	if(result=="bottom center"){$('#'+beingdragged).animate({top: dropy+h2, left: dropx}, 'fast')	}
	 	if(result=="bottom left"){$('#'+beingdragged).animate({top: dropy+(h2/2), left: dropx-w2}, 'fast')	}
	 	if(result=="bottom right"){$('#'+beingdragged).animate({top: dropy+(h2/2), left: dropx+w2}, 'fast')	}
	 	if(result=="top left"){$('#'+beingdragged).animate({top: dropy-(h2/2), left: dropx-w2}, 'fast')	}
	 	if(result=="top right"){$('#'+beingdragged).animate({top: dropy-(h2/2), left: dropx+w2}, 'fast')}
	 	setTimeout("showIconsOnMouseUp()",500);
		}
	 else
	 	{
		showIconsOnMouseUp()
		}
}

function showIconsOnMouseUp()
{
c=$('#'+beingdragged).offset()
		t=c.top;l=c.left;
		$("#iconsWrapper").css({"top":t-90,"left":l-15})
		$("#iconsWrapper").show()
}
  
function collision($div1, $div2) 
{
	  x1 = $div1.offset().left;y1 = $div1.offset().top;
	  h1 = $div1.outerHeight(true);w1 = $div1.outerWidth(true);
	  b1 = y1+h1;r1 = x1+w1;x2 = $div2.offset().left;
	  y2 = $div2.offset().top;h2 = $div2.outerHeight(true);w2 = $div2.outerWidth(true);
	  b2 = y2+h2;r2=x2+w2;
	  if (b1<y2||y1>b2||r1<x2||x1>r2) return false;
	  t=Math.abs(x1-x2)
	  str="bottom"
	  if(y1<y2){str="top"}
	  str2="center"
	   if(x1<x2)	
		{str2= "left";if(t<(w1/2)){str2= "center"}}
	  else if(x1>x2)
		{str2= "right";if(t<(w1/2)){str2= "center"}}
	  return str+" "+str2
}