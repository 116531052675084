var mode ="";
function initializeHoneyCombDelivery(loadedData,localmode)
{
    $("body").nodoubletapzoom();//prevent zoom on ipad when double click

    //Detects whether touch screen ebabled.
    ipadUser = false
    supportsTouch = 'ontouchstart' in window || navigator.msMaxTouchPoints;
    if (supportsTouch == true) {
        ipadUser = true
    }

    
       // debugger;
    //Colorpicker
    var $box = $('#colorPicker');
    $box.tinycolorpicker();
    var box = $box.data("plugin_tinycolorpicker");
    $box.bind("change", function () {
        //console.log(box.colorHex);
        $("#" + elId).css({ "background-color": "#" + box.colorHex })
        var rgb = hexToRgb(box.colorHex);
            var color = getFontColorFromBackgroundRBG(rgb.r,rgb.b,rgb.g);
            $("#" + elId).css({ "color": color })
            SaveDroppedHexagonState(elId);

    });
    $(".colorInner").click(function () {
        $(".colorInner").show()
    })
     

    //Double click to create a fresh hexagon on the canvas
    $("#overlay").dblclick(function (event) {

        var menu = parseInt($("#menu").css("width").replace("px")) + parseInt($("#menu").css("margin-left").replace("px"));
        var header = parseInt($("#header").css("height").replace("px"));

        var x = event.pageX - menu; //- this.offsetLeft;
        var y = event.pageY - header; //- this.offsetTop;
        totalhexagons++
        $(".honeycomb").append('<div id="h' + totalhexagons + '" class="hexagon"><div class="hexagontent">Use the edit icon to change the text</div></div>')
        $("#h" + totalhexagons).css({ "top": y - 55, "left": x - 55 })
    });


    //Delete hexagon (send to trash)
    $("#trashCan").click(function (event) {
        deletingId = elId
        apprise("<b>You are about to delete this hexagon from the canvas.</b>",
            { 'confirm': true },
            function (r) {
                if (r) {
                    playSound("deleteAudio")
                    $("#iconsWrapper").css({ "display": "none" })
                    $("#" + deletingId).fadeOut("slow", function () {
                        $("#" + deletingId).css({ "left": 9999 })//push the hexagon off the page so if edit cancelled, it still remains invisible.
                    });
                }
            }
        );



    });

    //Edit text within a particular hexagon
    editingText = false;
    $('#edit').click(function () {
        editingText = true
        numb = elId.substr(0, elId.length - 1)
        //console.log("numb="+numb);
        $("#" + elId).addClass("zoomIn");
        $("#" + elId).draggable({ disabled: true });
        $("#" + elId + " .hexagontent").attr('contenteditable', 'true');
        $("#" + elId + " .hexagontent").css({ "cursor": "text" })
        $("#" + elId + " .hexagontent").focus();
        $("#iconsWrapper").hide()
        $("#" + elId).on('blur', '.hexagontent', function () {
            editingText = false
            txt = $("#h" + numb + " .hexagontent").html();
            //console.log(numb+"="+txt)
            $("#" + elId).removeClass("zoomIn").addClass("zoomOut");
            rebuildListFromCanvas()
            //console.log("blur - "+elId);
            $("#" + elId).draggable({ disabled: false });
            $("#" + elId + " .hexagontent").attr('contenteditable', 'false');
            $("#" + elId + " .hexagontent").css({ "cursor": "move" })
        });
    })
    
       
     var $panzoom = $("#ZoomDiv").panzoom({
         $zoomIn: $(".zoom-in"),
         $zoomOut: $(".zoom-out"),
         $zoomRange: $(".zoom-range"),
         $reset: $(".reset"),
         startTransform: 'scale(1.0)',
         minScale: 0.3,
         maxScale:1.0
     });
    
    // $panzoom.parent().on('mousewheel.focal', function (e) {
    //     e.preventDefault();
    //     var delta = e.delta || e.originalEvent.wheelDelta;
    //     var zoomOut = delta ? delta < 0 : e.originalEvent.deltaY > 0;
    //     $panzoom.panzoom('zoom', zoomOut, {
    //         increment: 0.1,
    //         animate: false,
    //         focal: e
    //     });
    // });

   // debugger;
    mode = localmode;
   // $('#iconsWrapper').hide();
    var theTitle ="";
        hex_x = new Array()
        hex_y = new Array()
        cols = new Array()
        hex_id = new Array()
        hex_type = new Array()
        hex_fontcolor=new Array()
        hex_percentage = new Array();
        hex_performance = new Array();
        hex_description = new Array();
        loadingFromServer = 1//important - tells 'createhexagons()' not to go for default 'honeycomb' layout.
        q = new Array()
        str = ""
        var eleOverlay = document.getElementById("overlay");
        $('#overlay').css('min-height','600px');
        $('#overlay').css('width','98%');
        $('#overlay').css('background-color','#ffffff');
        debugger;
        var top = eleOverlay.offsetTop;
        var left = eleOverlay.offsetLeft;
        var height = eleOverlay.offsetHeight;
        var width = eleOverlay.offsetWidth;
        console.log('page width to display honeycomb overlay:'+ width);
        var tabWrapper  = document.getElementsByClassName("mat-tab-body-wrapper")[0];
        var tabWrapperWidth= tabWrapper.offsetWidth;
        width=tabWrapperWidth-102;
        
        console.log('page width to display honeycomb:'+ width);
        var startHexLeft = left + 15;
        var hexLeft = startHexLeft;
        var hexTop = 0; 
        hex_top = [top+10,top+10+60];
        temp = loadedData.split("\n");
       var topIndex=0;
       var partialpositioning=false;
       var isInitialLoad= false;
       var lastHexLeft=0;
       var lastHexTop=0;
       var initalLoad="";
       var hexagonType="";
       temp = loadedData.split("\n");
        for (n = 0; n < temp.length; n++) {
            bits = temp[n].split("||");
            if(n==0 && bits[1]=='X')
            {
                isInitialLoad=true;
                hexagonType= bits[5];

            }
            if(bits[1]=='X')
            {
                if(partialpositioning)
                {
                    hex_top = [parseFloat(lastHexTop)+70,parseFloat(lastHexTop)+140];
                    partialpositioning=false;
                }
                
                if((hexLeft)>=(width-10))
                {
                    hexLeft= startHexLeft;
                    topIndex =0;
                    hex_top[0] = hex_top[0] + 118;
                    hex_top[1] = hex_top[1] + 118;
                    hexTop = hex_top[topIndex]; 
                    topIndex = topIndex ==0?1:0;
                // console.log('hexLeft:'+ hexLeft);
                }
                else 
                {
                    //hexLeft = hexLeft + 102;
                    hexTop = hex_top[topIndex]; 
                    topIndex = topIndex ==0?1:0;
                }
            }
            else
            {   
                partialpositioning=true;
                lastHexLeft= bits[1];
                lastHexTop= bits[2];
            }
            //debugger;
            str += bits[0] + "\n";
            hex_x[n] = bits[1]=='X'? hexLeft : bits[1];// parseInt(bits[1])
            hex_y[n] = bits[2]=='Y'? hexTop : bits[2];// parseInt(bits[2])
            hexLeft = hexLeft + 102;
            hex_id[n]=bits[4]
            hex_type[n]=bits[5]
            cols[n] = bits[3]
            hex_percentage[n] = bits[7];
            hex_performance[n] = bits[8];
            hex_description[n]=bits[9];
            if(isInitialLoad)
            { 
                if(hex_id[n]!=undefined  && hex_id[n]!='undefined')
                {
                    initalLoad= initalLoad==""? hex_id[n] +'|'+hex_x[n] +'|'+hex_y[n]:initalLoad+','+hex_id[n] +'|'+hex_x[n] +'|'+hex_y[n];
                }
            }
            if(hex_percentage[n] == "undefined" || hex_percentage[n] == undefined || hex_percentage[n] == "null" || hex_percentage[n] == null || hex_percentage[n] == ''){
                hex_percentage[n] = '';
               }
               else
               {
                hex_percentage[n] =  hex_percentage[n] +'%';
                hex_performance[n] = '';
               }
               if(hex_performance[n] == "undefined" || hex_performance[n] == undefined || hex_performance[n] == "null" || hex_performance[n] == null || hex_performance[n] == ''|| hex_performance[n] == "0" || hex_performance[n] <= 0){
                hex_performance[n] = '';
               }
               else
               {
                hex_performance[n] =  hex_performance[n] +'%';
               }
            if(cols[n]=="null")
            {
               // debugger;
                cols[n]="#6699FF";
            }
            var rgb = cols[n]==null?{r:0,g:0,b:0}: hexToRgb(cols[n]);
           
            hex_fontcolor[n]= rgb==null?"#ffffff":getFontColorFromBackgroundRBG(rgb.r,rgb.b,rgb.g);
        }

        $("#qbox").val(str);
        $("#titleBox").val(theTitle);
        //$("#ibox").val(theInstructions);
        //}
        debugger;
        if(isInitialLoad && localmode!="read")
        {
        document.getElementById("hid_honeycombState").value= initalLoad;
        document.getElementById("hid_hexgonType").value= hexagonType;
        var initLoad= document.getElementById("div_initialState");
        initLoad.click();
        }
        createDeliveryHoneyComb(localmode,hex_id,hex_x,hex_y,cols,hex_type,hex_fontcolor,hex_percentage, hex_performance,hex_description); 
}
//Disables default "double-tap=zoom" on ipad, to allow for creation of new hexagon instead.
(function ($) {

    //$("#wrapper").css("position", "fixed").css("width", "100%");
    $.fn.nodoubletapzoom = function () {
        $(this).bind('touchstart', function preventZoom(event) {
            var t2 = event.timeStamp;
            var t1 = $(this).data('lastTouch') || t2;
            var dt = t2 - t1;
            var fingers = event.originalEvent.touches.length;
            $(this).data('lastTouch', t2);
            if (!dt || dt > 500 || fingers > 1) {
                return; // not double-tap
            }
            var x = event.originalEvent.touches[0].pageX
            var y = event.originalEvent.touches[0].pageY
            event.preventDefault(); // double tap - prevent the zoom
            totalhexagons++
            $(".honeycomb").append('<div id="h' + totalhexagons + '" class="hexagon"><div class="hexagontent">Use the edit icon to change the text</div></div>')
            $("#h" + totalhexagons).css({ "top": y - 55, "left": x - 55 })
        });
    };
})(jQuery);
function playSound(id) { audio = document.getElementById(id); audio.play(); }

function hexToRgb(hex) {
    var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result ? {
      r: parseInt(result[1], 16),
      g: parseInt(result[2], 16),
      b: parseInt(result[3], 16)
    } : null;
  }
  function getFontColorFromBackgroundRBG(r,b,g)
  {
    var luma = ((0.299*r) + (0.587 * g) + (0.114 * b)) / 255;

// Return black for bright colors, white for dark colors
    return luma > 0.5 ? "#000000" : "#ffffff";
  }
function createDeliveryHoneyComb(mode,hex_id,hex_x,hex_y,cols,hex_type,hex_fontcolor,hex_percentage, hex_performance,hex_description) {
    newd = validate()
    if (newd == false) { return }
    else
    {
        $("#qbox").val(newd.join("\n") + "\n")
        loadedData = newd.join("\n")
        theTitle = $("#titleBox").val()
        if (theTitle == "") { apprise("Please give your activity a title"); return }
        var temp = premiumUserName;
        if (temp == "") { temp = "NONE" }
        $(".honeycomb").show();
        $("#createDivWrapper").hide();
        displayHexagonsDelivery(loadedData,mode,hex_id,hex_x,hex_y,cols,hex_type,hex_fontcolor,hex_percentage, hex_performance,hex_description);


    }

}
function displayHexagonsDelivery(loadedData,mode,hex_id,hex_x,hex_y,cols,hex_type,hex_fontcolor,hex_percentage, hex_performance,hex_description) {
    $("#cancelBut").show()
    currentlydragging = 0
    originalX= 0;
    originalY=0;

    h = loadedData.split("\n");
    str = ""
    totalhexagons = h.length;
    for (n = 0; n < h.length; n++) {
        //hexProperties= h.split("||");
       // var style ="top:'"+hex_y[n]+"px';left:'"+hex_x[n]+"px';background-color:'"+cols[n]+"'"
        str += '<div id="tooltip' + hex_id[n] + '_' + hex_type[n]+'" style="display:none;">'+ hex_description[n] + '</div><div id="h' + hex_id[n] + '_' + hex_type[n]+'" class="hexagon"  ><div style ="padding-bottom: 100px !important;  font-size:15px !important;" class="hexagontent">'+ hex_performance[n] +'</div><div class="hexagontent">' + h[n]  + '</div><div style ="padding-top: 100px !important; font-size:15px !important;" class="hexagontent">'+ hex_percentage[n] +'</div></div>'
    }

    $(".honeycomb").html(str)
    if (mode != "read") {
        for (n = 0; n < h.length; n++) {
            if (!$("#h" + hex_id[n] + '_' + hex_type[n]).is(":ui-draggable"))//Adds draggable immediately to existing hexagons.
            {
                $("#h" + hex_id[n] + '_' + hex_type[n]).draggable({
                    stack: ".hexagon",
                    scroll: false,
                    start: function (event, ui) //draggable:
                    {
                        $("#iconsWrapper").hide()
                        $(this).removeClass("zoomIn");
                        currentlydragging = 1; playSound("upAudio"); beingdragged = event.target.id;//console.log(beingdragged);
                    },
                    stop: function (event, ui) //snappable:
                    {
                        currentlydragging = 0; playSound("downAudio"); checkforhit(beingdragged); $(this).removeClass("zoomIn").addClass("zoomOut");
                        $('.hexagon').css('cursor', 'move');
                    }
                });
            }

        }
    }



    //Zoom in when MouseDown
    $('.honeycomb').on('touchstart mousedown', '.hexagon', function () {
        if(mode!="read")
        {
            if (currentlydragging == 0) {
                h = loadedData.split("\n");
    
                for (n = 0; n < h.length; n++) {
                    $("#tooltip"+hex_id[n]+"_"+hex_type[n]).css("display","none");
                    $("#h"+hex_id[n]+"_"+hex_type[n]).css("z-index","10");
                }
                originalX= $(this).position().left;
                originalY=$(this).position().top;
                $("#iconsWrapper").hide()
                $('.hexagon').css('z-index', '1');
                $(this).css('z-index', '10');//bring to front.
                $(this).addClass("zoomIn");
                $("#ZoomDiv").panzoom("disable");
            }
        }
    })

    //Zoom back out when MouseUp
    $('.honeycomb').on('touchend mouseup', '.hexagon', function (e) {
debugger;
        //console.log("editingText = "+editingText)
        //if (editingText != true) {
        //    $(this).removeClass("zoomIn").addClass("zoomOut");
        //}
        if (mode != "read") {
            var target = $(e.target);
            var newelId = beingdragged;
            console.log("mouseup");
            console.log('Hex ID:'+ newelId)
            //SaveDroppedHexagonState(newelId,"before timeout");
            $("#ZoomDiv").panzoom("enable");
            console.log("before save");
            setTimeout(() => {
                SaveDroppedHexagonState(newelId);

            }, 500);
               
            console.log("after save");

        }
    })
   // debugger;   
    if (mode == "read") {

        $('.honeycomb').on('touchstart click', '.hexagon', function (e) {
            var target = $(e.target);
            newelId = target.attr('id');
           // debugger;
            document.getElementById("hidHexID").value=newelId;
            hexagonClicked(newelId);
           // scope.onHexClick({ args: 'Hello World' });
            $(this).removeClass("zoomIn").addClass("zoomOut");
        })

        if (mode == "") {    
        $('.honeycomb').on('mouseover', '.hexagon, .hexagontent', function (e) {
           //debugger;
            $(this).css("cursor", "pointer");
            $('.hexagon').css('z-index', '1');
            $(this).css('z-index', '10');//bring to front.
            $(this).removeClass("zoomOut").addClass("zoomIn");
            var id= e.target.attr('id');
        });
    }

    }

    //Show icons on MouseEnter
    
       // debugger;
        $('.honeycomb').on('mouseenter', '.hexagon, .hexagontent', function (e) {
          //debugger;
          //$("#ZoomDiv").panzoom("disable");


                var target = $(e.target);
                newelId = target.attr('id');
                //console.log("ElID="+elId)
                if(newelId==undefined)
                    {

                    //console.log("text hover");
                    newelId=$(this).parent().attr("id");
                    }
                if(newelId!=undefined)
                    {
                    elId=newelId
                    c=$("#"+elId).offset()
                    t=c.top;l=c.left;
                    cT=  parseInt($("#"+elId).css('top').replace("px"));
                    cL = parseInt($("#"+elId).css('left').replace("px"));
                    //var menu = parseInt($("#nav-container").css("width").replace("px")) + parseInt($("#nav-container").css("margin-left").replace("px"));
                    var headerT = $("#appHeader");
                    var headerCSS= $("#appHeader").css("height");
                    var headerR=  parseInt($("#appHeader").css("height").replace("px")) ;
                    var header = parseInt($("#appHeader").css("height").replace("px")) ;
                    if (mode != "read") {
                    $("#iconsWrapper").css({ "top": cT-85, "left": cL + 8 })
                    if(currentlydragging==0){$("#iconsWrapper").show()}
                    }
                    else
                    {
                        $("#"+elId).css("cursor", "pointer");
                    }
                    var id_type= elId.substring(1);
                   // $("#tooltip"+id_type).css();
                   $("#"+elId).css("z-index","10");
                    $("#tooltip"+id_type).css({ "top": cT+60, "left": cL + 8,"display":"flex" ,"z-index":20,"position":"absolute","padding":"5px 5px 5px 5px","background-color": "white"})
                    $("#ZoomDiv").panzoom("disable");                            
                    h = loadedData.split("\n");
                    for(var n=0;n<h.length;n++)
                    {
                        if(id_type!=hex_id[n]+"_"+hex_type[n])
                        {
                            $("#tooltip"+hex_id[n]+"_"+hex_type[n]).css("display","none");
                            $("#h"+hex_id[n]+"_"+hex_type[n]).css("z-index","10");
                            
                        }                        
                    }
                    }
            })
    


    //Remove icons on MouseOut
    $('.honeycomb').on('mouseout', '.hexagon', function (e) {

        $(this).removeClass("zoomIn").addClass("zoomOut");
        var target = $(e.target);
        newelId = target.attr('id');
        if (newelId != undefined) { elId = newelId; $("#iconsWrapper").hide() }
        $("#tooltip"+hex_id[n]+"_"+hex_type[n]).css("display","none");
        
        if (newelId != undefined){
            var id_type= newelId.substring(1);
            $("#tooltip"+id_type).css("display","none");
        }
        h = loadedData.split("\n");
                    for(var n=0;n<h.length;n++)
                    {
                        
                            $("#tooltip"+hex_id[n]+"_"+hex_type[n]).css("display","none");
                            $("#h"+hex_id[n]+"_"+hex_type[n]).css("z-index","");
                            
                                              
                    } 
        $("#ZoomDiv").panzoom("enable");                            
             
        //$("#ZoomDiv").panzoom("enable");
        // var $panzoom = $("#ZoomDiv").panzoom({
        //     $zoomIn: $(".zoom-in"),
        //     $zoomOut: $(".zoom-out"),
        //     $zoomRange: $(".zoom-range"),
        //     $reset: $(".reset"),
        //     startTransform: 'scale(1.0)',
        //     minScale: 0.1
        // });
    })


    if (mode != "read") {
        //Make hexagons draggable / snappable.
        $(".honeycomb").draggableChildren(".hexagon", {
            stack: ".hexagon",
            scroll: false,
            start: function (event, ui) //draggable:
            {
                $("#iconsWrapper").hide()
                currentlydragging = 1; playSound("upAudio"); beingdragged = event.target.id;//console.log(beingdragged);
            },
            stop: function (event, ui) //snappable:
            {
                currentlydragging = 0; playSound("downAudio"); checkforhit(beingdragged); $(this).removeClass("zoomIn").addClass("zoomOut");
                $('.hexagon').css('cursor', 'move');
            }
        });
    }



    //bug fix
    $('#iconsWrapper').on("mouseenter", function (e) {
        //debugger;
        $("#iconsWrapper").show()
    });


    if (loadingFromServer == 0) //Display the initial hexagons in a honeycomb
    {
        t = 150; l = 53; dir = 1
        for (n = 1; n <= h.length; n++) {
            l += 102
            if ((l + 150) > $(window).width()) {
                l = 155; t += 58; if (dir == 1) { t += 60 }
                dir = 1;
            }
            $("#h" + n).css({ "top": t, "left": l, "background-color": "#6699FF" })
            if (dir == 1)
            { t += 60 }
            else { t -= 60 }
            if (dir == 1) { dirnew = -1 }
            if (dir == -1) { dirnew = 1 }
            dir = dirnew
        }
    }
    else //Display pre-saved hexagons in their proper layout / colour
    {
        for (n = 0; n < h.length; n++) {
            //debugger;
           // hexProperties= h.split('||');
           $("#h" + hex_id[n]+ "_" + hex_type[n]).css({ "top": hex_y[n] +"px", "left": hex_x[n] + "px" })
           $("#h" + hex_id[n]+ "_" + hex_type[n]).css({ 'background-color': '' })
           $("#h" + hex_id[n]+ "_" + hex_type[n]).css({ "background-color": "#" + cols[n] })
           $("#h" + hex_id[n]+ "_" + hex_type[n]).css({ "color": "#" + hex_fontcolor[n] })
          
           if(hex_percentage[n].includes('%')){
            $("#h" + hex_id[n]+ "_" + hex_type[n]).css({ "opacity": "0.5" })
           }
            //console.log(hex_x[n])
        }
        loadingFromServer = 0 //so that if edit, then create, we get the honeycomb rather than the old layout.

    }

    $(".hexagon, #options, #activityTitle").show()

    if (initialmessage == false) {
        initialmessage = true;//apprise("To create a new hexagon, double-click anywhere in the canvas.");
    }



}



function validate() {
    newq = new Array()
    t = $("#qbox").val().split("\n");
    //if (t.length < 5) { apprise("You must provide text for a minimum of 5 hexagons"); return false }
    //if(t.length>30){apprise("You can only produce a maxmimum of 30 hexagons");return false}
    for (n = 0; n < t.length; n++) { if (t[n] != "") { newq.push(t[n]); } }
    return (newq);
}

function SaveDroppedHexagonState(id)
{
debugger;
var x="";
var y=""; 
 x = $('#'+id).position().left// parseInt(bits[1])
 y = $('#'+id).position().top;// parseInt(bits[2])
 
 col = $('#'+id).css('backgroundColor');
 col= hexc(col);
fontcol =   $('#'+id).css('color');
var id_type=id.substring(1);
var id_typesplit= id_type.split('_');
var hexagonState = ""
hexagonState = x + '||' + y + '||'+ col + '||' + id_typesplit[0] + '||' + id_typesplit[1];
document.getElementById("hid_honeycombState").value= hexagonState;
var dropped= document.getElementById("div_simulateDrop");
dropped.click();
}

function save() {
    newd = validate()
    $("#qbox").val(newd.join("\n") + "\n")
    instr = $("#ibox").val()
    loadedData = "";
   // debugger;
    for (n = 0; n < newd.length; n++) {
        bits = newd[n].split("||");
            str += bits[0] + "\n";
            x = $('#h'+ hex_id[n] + '_' + hex_type[n]).position().left// parseInt(bits[1])
            y = $('#h'+ hex_id[n] + '_' + hex_type[n]).position().top;// parseInt(bits[2])
            id=hex_id[n];
            type=hex_type[n];

            //hex_type[n]=bits[5]
            //cols[n] = bits[3]
            if(cols[n]=="null")
            {
               // debugger;
                cols[n]="#6699FF";
            }
            //var rgb = cols[n]==null?{r:0,g:0,b:0}: hexToRgb(cols[n]);
           
            //hex_fontcolor[n]= rgb==null?"#ffffff":getFontColorFromBackgroundRBG(rgb.r,rgb.b,rgb.g);
        
        col = $('#h'+ hex_id[n] + '_' + hex_type[n]).css('backgroundColor');
        fontcol =   $('#h'+ hex_id[n] + '_' + hex_type[n]).css('color');
        col = hexc(col)
        fontcol= hexc(fontcol)
        loadedData += newd[n] + "||" + x + "||" + y + "||" + col + "||" + id +"||" + type + "||"  + fontcol + "\n";
    }
    debugger;
    theTitle = $("#titleBox").val()
    if (theTitle == "") { apprise("Please give your activity a title"); return }
    var temp = premiumUserName;
    if (temp == "") { temp = "NONE" }
    
   // saveit("hexagon||PremiumUserName=" + temp + "||\n*****\n" + loadedData + "\n*****\n" + theTitle + "\n*****\n" + instr)
    //saveHoneycombState(loadedData);
}
function hexagonDrop()
{


}

function hexc(rgb) {
    if (rgb.search("rgb") == -1) {
        return rgb;
    } else {
        rgb = rgb.match(/^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+))?\)$/);
        function hex(x) {
            return ("0" + parseInt(x).toString(16)).slice(-2);
        }
        return "#" + hex(rgb[1]) + hex(rgb[2]) + hex(rgb[3]);
    }
}